import axios from 'axios'
import qs from 'qs'
import { message } from 'antd';

// let baseURL = ''
// // 根据环境变量区分接口的默认地址
// switch (process.env.NODE_ENV) {
//     case 'production':
//         baseURL = 'https://www.baidu.com/'
//         break
//     case 'test':
//         baseURL = 'https://www.test.com'
//         break
//     case 'development':
//         baseURL = '/api_news'
//         break
//     default:
//         baseURL = '/api_news'
//         break
// }

const myAxios = axios.create({
    // baseURL,
    timeout: 10000,  // 设置超时时间和跨域是否允许携带凭证
    withCredentials: false,  //跨域过程中资源凭证携带,如果为false，如法传递cookie
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }  // 设置请求传递数据的格式(看服务器要求什么格式)
})

// 添加请求拦截器
myAxios.interceptors.request.use(
    // 携带token
    config => {
        const token = localStorage.getItem('token')
        token && (config.headers.Authorization = token)
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// 添加响应拦截器
myAxios.interceptors.response.use(
    response => {
        return response.data  // 可以选择将response.data返回
        // return Promise.resolve(response)
    },
    err => {
        const { response } = err
        if (response) {  // 失败服务器有返回结果
            switch (response.status) {
                case 401:  // 当前请求需要用户验证（一般是未登录）
                    // 跳转登录
                    break
                case 403:  // 服务器已经理解请求，但是拒绝执行它（一般是token过期）
                    setTimeout(() => {
                        // 跳转登录页
                    }, 1000);
                    break
                case 404:  // 请求失败，请求所希望得到的资源未被在服务器上发现
                    console.log('请求资源不存在');
                    break
                default:
                    message.error(response.data.message)
                    break
            }
            return Promise.reject(response)
        } else {  // 失败服务器无返回结果
            if (!window.navigator.onLine) {
                // 断网处理：可以跳转到断网页面
                return
            } else {
                return Promise.reject(err)
            }
        }
    }
)


const http = {
    Get(url, params) {
        return new Promise((resolve, reject) => {
            myAxios.get(url, {
                params,
                paramsSerializer: (params) => {  // 传递数组需要使用paramsSerializer进行序列化
                    return qs.stringify(params, { indices: false })
                }
            }).then(value => resolve(value)).catch(err => reject(err))
        })
    },

    Post(url, params) {
        let data = qs.stringify(params)
        return new Promise((resolve, reject) => {
            myAxios.post(url, data).then(value => resolve(value)).catch(err => reject(err))
        })
    },

    Put(url, params) {
        return new Promise((resolve, reject) => {
            const data = qs.stringify(params)
            myAxios.put(url, data).then(value => resolve(value)).catch(err => reject(err))
        })
    },

    Delete(url, params) {
        return new Promise((resolve, reject) => {
            const data = qs.stringify(params)
            myAxios.delete(url, data).then(value => resolve(value)).catch(err => reject(err))
        })
    },
}

export default http