import React, {useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import './index.scss'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Carousel } from 'antd';
import {Map, Marker, InfoWindow} from 'react-bmapgl';

export default function AboutUs() {
    const navigate = useNavigate()
    
    const cultures = useRef()
    const changeCultures = (type) => {
        type === 'r' ? cultures.current.next() : cultures.current.prev()
    }
    const [mapStr] = React.useState(`
        <table>
            <tr><td> 地址: 杭州市江干区万象城3幢801至807室</td></tr>
            <tr><td> 邮编: 310000</td></tr>
            
            <tr><td> 网址: www.weiyir.com</td></tr>
        </table>
    `)
{/* <tr><td> 客服: 4008128089</td></tr> */}
    const toForm = (e) => {
        if(e.target.nodeName === 'A' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
        navigate('/form', {
            state: {
                isClient: true
            }
        })
    }
    return (
        <div className='aboutUs'>
            <div className="banner" onClick={toForm}>
                <Header></Header>
                <img src={require('./imgs/banner.png')} alt="" className='banner-pic' />
            </div>
            <div className="about">
                <div className="content-bg">
                    <div className="content">
                        <div className="left">
                            <div className="title">About</div>
                            <span className="title-s">关于微易融</span>
                            <div className='line-box'>
                                ABOUT <div className="line"></div>
                            </div>
                            <h2>杭州微易融信息技术有限公司</h2>
                            <p>杭州微易融是一家以市场为驱动，以科技为核心的金融服务平台，提供专业的银行信贷居间业务，商业合作渠道涵盖全国数十家银行金融机构。 在经营管理上公司追求规模化、规范化、服务一体化，致力于让每一位客户享有最优质的服务；在业务专业上公司致力于改变这个行业的形象。</p>
                            <div className="btn" onClick={toForm}>立即咨询 <img src={require('../../assect/imgs/arrow_right.png')} alt="" /></div>
                        </div>
                        <div className="right">
                            <img src={require('./imgs/about-pic.png')} alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="cultures">
                <div className="content">
                    <Carousel className='culturesItems' dots={false} ref={cultures}>
                        <>
                            <div className='culturesItem'>
                                <div className="left">
                                    <img src={require('./imgs/cultures-pic1.png')} alt="" />
                                </div>
                                <div className="right">
                                    <div className="title">Corporate Cultures</div>
                                    <span className="title-s">企业文化</span>
                                    <h2>我们的核心价值观</h2>
                                    <p>客户至上 高效工作 诚实正直 信守承诺 多方共赢</p>
                                    <div className="btn" onClick={toForm}>立即咨询 <img src={require('../../assect/imgs/arrow_right.png')} alt="" /></div>
                                    <div className="btns">
                                        <div className="l" onClick={() => changeCultures('l')}>
                                            <img src={require('../../assect/imgs/left.png')} alt="" />
                                        </div>
                                        <div className="r" onClick={() => changeCultures('r')}>
                                            <img src={require('../../assect/imgs/right.png')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        <>
                            <div className='culturesItem'>
                                <div className="left">
                                    <img src={require('./imgs/cultures-pic2.png')} alt="" />
                                </div>
                                <div className="right">
                                    <div className="title">Corporate Cultures</div>
                                    <span className="title-s">企业文化</span>
                                    <h2>我们的服务承诺</h2>
                                    <p>承诺遵纪守法 承诺不做不实承诺 承诺不传播负能量 承诺合理收费</p>
                                    <div className="btn" onClick={toForm}>立即咨询 <img src={require('../../assect/imgs/arrow_right.png')} alt="" /></div>
                                    <div className="btns">
                                        <div className="l" onClick={() => changeCultures('l')}>
                                            <img src={require('../../assect/imgs/left.png')} alt="" />
                                        </div>
                                        <div className="r" onClick={() => changeCultures('r')}>
                                            <img src={require('../../assect/imgs/right.png')} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Carousel>
                </div>
            </div>
            <div className="map">
                <div className="content">
                    <div className="text">
                        <div className="title">Connect</div>
                        <span className="title-s">联系我们</span>
                        <h3>微易融</h3>
                        {/* <p>
                            <img src={require('./imgs/phone.png')} alt="" />
                            4008128089
                        </p> */}
                        <p>
                            <img src={require('./imgs/web.png')} alt="" />
                            www.weiyir.com
                        </p>
                        <p>
                            <img src={require('./imgs/location.png')} alt="" />
                            杭州市江干区万象城3幢801至807室
                        </p>
                            <img src={require('./imgs/erweima.png')} alt="" className="pic" />
                    </div>
                    <div className="baiduMap">
                        <Map center={{lng: 120.220826, lat: 30.259551}} zoom="11" style={{ height: '100%' }}>
                            <Marker position={{lng: 120.220826, lat: 30.259551}} />
                            <InfoWindow position={{lng: 120.220826, lat: 30.259551}} text={mapStr} title='标题'/>
                        </Map>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}